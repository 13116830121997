import React, { useEffect, useState } from 'react';
import useQueryParam from '../../utils/UseQueryParam';
import { hostname } from '../../utils/constants';
import SiteLayout from '../../components/layout/SiteLayout';
import { Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import loadable from '@loadable/component';
const RoContact = loadable(() => import('../../templates/homepage/ro-contact'));

const Features = (props) => {
  const [posts, setPosts] = useState(props.data.features.posts.filter(post => post.frontmatter.type === 'feature'));
  const [postsByCategory, setPostsByCategory] = useState(props.data.features.posts.filter(post => post.frontmatter.type === 'feature'));
  const [categories, setCategories] = useState([]);
  const [texts, setTexts] = useState(props?.data?.features?.posts?.filter(post => post.frontmatter.type === 'feature-index' || post.frontmatter.type.indexOf('feature-category') > -1)[0]);

  const SHOW_ALL = "Show all";
  const [selectedCategory, setSelectedCategory] = useState(props.category ? props.category : SHOW_ALL);

  let ldJsonHome = "Strona główna";
  let ldJsonLevel1Route = "/funkcje/";
  let ldJsonLevel1Name = "Funkcje";

  let navigationLdJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": hostname + "/",
          "name": ldJsonHome
        }
      },
      {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": hostname + ldJsonLevel1Route,
          "name": ldJsonLevel1Name
        }
      }
    ]
  };

  useEffect(() => {
    getCategories();
  }, [posts]);

  useEffect(() => {
    if (selectedCategory === SHOW_ALL) {
      setPostsByCategory(posts);
    } else {
      setPostsByCategory(posts.filter(post => post.exports.categories.filter(category => category.name.toLowerCase() === selectedCategory.toLowerCase()).length === 1).sort((a, b) => a.exports.categories.filter(category => category.name.toLowerCase() === selectedCategory.toLowerCase())[0].index - b.exports.categories.filter(category => category.name.toLowerCase() === selectedCategory.toLowerCase())[0].index));
    }
  }, [selectedCategory]);

  const getCategories = () => {
    let tempCategories = [];
    for (let post of posts) {
      for (let category of post.exports.categories) {
        if (!tempCategories.includes(category.name)) {
          tempCategories.push(category.name);
        }
      }
    }
    setCategories(tempCategories);
  };

  return (
    <SiteLayout relatedPages={texts?.fields?.relatedPages} lang={texts?.fields?.lang} canonical={texts?.fields?.canonical.replace("widok-z-lotu-ptaka/", "").replace("wspolpraca/", "").replace("zarzadzanie-zadaniami/", "").replace("planowanie-i-prognoza/", "").replace("finansowy/", "")} useCanonical={true} title={"Features"} seo={{metaTitle: texts?.frontmatter?.metaTitle, metaDescription: texts?.frontmatter?.metaDescription, metaImage: texts?.frontmatter?.metaImage?.publicURL, metaCover: texts?.frontmatter?.metaCover?.publicURL, twitterCard: texts?.frontmatter?.twitterCard, ldJson: texts?.frontmatter?.ldJson, navigationLdJson: navigationLdJson, keywords: texts?.frontmatter?.metaKeywords, slug: texts?.fields?.slug.replace("/posts/index", "")}}>
      <div className={"w-full flex flex-col features-bg-image px-5 md:px-10 lg:px-20 xl:px-60 xxl:px-96 "}>
        <div className={"w-full"}>
          <p className={"text-right w-full text-sm text-gray-500 mt-5"}><Link to={"/"}>Strona główna</Link> > Funkcje</p>
        </div>
        <h1 className={"text-xl md:text-2xl lg:text-4xl text-center text-primary-default font-light pt-5 md:pt-10 md:pt-20"}>{texts?.frontmatter?.title}</h1>
      </div>
      <div className={"flex flex-col px-5 md:px-10 lg:px-20 xl:px-60 xxl:px-96  pt-10 text-sm text-gray-500 features-subtext"}>
        {texts?.body && <MDXRenderer>{texts.body}</MDXRenderer>}
      </div>
      <div className={"flex flex-row flex-wrap justify-center px-5 md:px-10 lg:px-20 xl:px-60 xxl:px-96   py-10"}>
        {categories && categories.length ? <div onClick={() => setSelectedCategory(SHOW_ALL)} className={"text-xs border-2 border-primary-default rounded-2xl uppercase p-2 px-5 hover:text-white hover:bg-primary-default cursor-pointer text-center m-2" + (selectedCategory === SHOW_ALL ? " text-white bg-primary-default" : " text-primary-default")}><span>Show all</span></div> : null}
        {categories && categories.length ? categories.map((category, index) => <div key={index} onClick={() => setSelectedCategory(category)} className={"text-xs border-2 border-primary-default rounded-2xl uppercase p-2 px-5 hover:text-white hover:bg-primary-default cursor-pointer text-center m-2" + (selectedCategory.toLowerCase() === category.toLowerCase() ? " text-white bg-primary-default" : " text-primary-default")}><span>{category}</span></div>) : null}
      </div>
      <div className={"flex flex-row flex-wrap px-5 md:px-10 lg:px-20 xl:px-60 xxl:px-96   py-10"}>
        {postsByCategory.map((feature, index) => (
          <Link to={feature.fields.slug.replace("/posts", "").replace("/ro/", "/")} className={"w-full md:w-1/2 xl:w-1/3"} key={index}>
            <div className={"mx-5 my-5"}>
              <div className={"flex flex-col w-full hover:bg-primary-default text-gray-600 hover:text-white items-center feature-card rounded-xl pt-2 bg-white"}>
                <img loading="lazy" src={feature?.frontmatter?.cover?.publicURL} alt={feature.frontmatter.coverAlt} className={"w-full bg-white card-image"} />
                <div className={"p-2 py-5 w-full flex flex-col bg-card-default feature-card-text rounded-b-xl"}>
                  <div>
                    <h2 className={"text-sm uppercase font-semibold pb-2"}>{feature.frontmatter.cardTitle}</h2>
                    <p className={"text-xs font-semibold"}>{feature.frontmatter.category}</p>
                    <p className={"text-xs pb-2 font-semibold text-gray-500 feature-more"}>{feature?.exports?.categories?.map((category, index) => category.name + (index < feature.exports.categories.length - 1 ? ", " : ""))}</p>
                  </div>
                  <p className={"text-sm xl:text-md pb-2"}>{feature?.frontmatter?.excerpt} <span className={"text-primary-blockquote feature-more"}>(...więcej)</span> {feature?.whiteSpace ? <pre>{feature.whiteSpace}</pre> : null}</p>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
      <RoContact />
    </SiteLayout>
  );
};

export default Features;
